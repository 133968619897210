import axios from "axios";
import { getCurrentUser, logout } from "./auth";

const API_URL = process.env.REACT_APP_API_URL || "";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const user = getCurrentUser();
    if (user && user.access_token) {
      config.headers["Authorization"] = `Bearer ${user.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      logout();
      // Redirect to login page
      window.location = "/#/login";
    }
    return Promise.reject(error);
  },
);

// API calls
// In api.js, update or add these functions:

export const getAgents = () => {
  return api.get("/admin/agents");
};

export const createAgent = (agentData) => {
  return api.post("/admin/agents", agentData);
};

export const updateAgent = (agentId, agentData) => {
  return api.put(`/admin/agents/${agentId}`, agentData);
};

export const deleteAgent = (agentId) => {
  return api.delete(`/admin/agents/${agentId}`);
};

export const getAgentChats = (agentId) => {
  return api.get(`/admin/agents/${agentId}/chats`);
};

export const deleteChat = (agentId, chatId) => {
  return api.delete(`/admin/agents/${agentId}/chats/${chatId}`);
};

export const getAgentById = (agentId) => {
  return api.get(`/admin/agents/${agentId}`);
};

export default api;
