import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  Snackbar,
  Chip,
  Typography,
  Box,
  AppBar,
  Toolbar,
  Container,
  Grid,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ContentCopy as ContentCopyIcon,
  OpenInNew as OpenInNewIcon,
  Info as InfoIcon,
  Dashboard as DashboardIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import {
  getAgents,
  createAgent,
  updateAgent,
  deleteAgent,
} from "../services/api";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  marginTop: theme.spacing(3),
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "& .MuiTableCell-head": {
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "80%",
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const initialFormState = {
  name: "",
  endpointUrl: "",
  apiKey: "",
  collectionId: "",
  prompt: "",
};

export default function Component() {
  const [agents, setAgents] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [collectionIds, setCollectionIds] = useState([]);

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await getAgents();
      setAgents(response.data);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setIsEditing(false);
    setFormData(initialFormState);
    setCollectionIds([]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCollectionIdChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, collectionId: value });
    setCollectionIds(
      value
        .split(",")
        .map((id) => id.trim())
        .filter((id) => id),
    );
  };

  const handleSubmit = async () => {
    try {
      const dataToSubmit = {
        ...formData,
        collectionId: collectionIds.join(","),
      };
      if (isEditing) {
        await updateAgent(dataToSubmit.id, dataToSubmit);
      } else {
        await createAgent(dataToSubmit);
      }
      fetchAgents();
      handleClose();
    } catch (error) {
      console.error("Error saving agent:", error);
    }
  };

  const handleEdit = (agent) => {
    setFormData(agent);
    setCollectionIds(
      agent.collectionId
        .split(",")
        .map((id) => id.trim())
        .filter((id) => id),
    );
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this agent?")) {
      try {
        await deleteAgent(id);
        fetchAgents();
      } catch (error) {
        console.error("Error deleting agent:", error);
      }
    }
  };

  const handleCopyId = (id) => {
    navigator.clipboard.writeText(`${window.location.origin}/${id}`);
    setSnackbarOpen(true);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? "..." + text.slice(-maxLength) : text;
  };

  const handleChipDelete = (idToDelete) => {
    setCollectionIds(collectionIds.filter((id) => id !== idToDelete));
    setFormData({
      ...formData,
      collectionId: collectionIds.filter((id) => id !== idToDelete).join(","),
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h4" component="h1" gutterBottom>
                  Manage Agents
                </Typography>
                <Tooltip title="Add new agent">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpen}
                  >
                    Add Agent
                  </Button>
                </Tooltip>
              </Box>
              <StyledTableContainer component={Paper}>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Troweb URL</TableCell>
                      <TableCell>API Key</TableCell>
                      <TableCell>Collection ID</TableCell>
                      <TableCell>Prompt</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {agents.map((agent) => (
                      <StyledTableRow key={agent.id}>
                        <TableCell>{agent.name}</TableCell>
                        <TableCell>
                          <Tooltip title="Copy agent URL">
                            <IconButton
                              onClick={() => handleCopyId(agent.id)}
                              size="small"
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{agent.endpointUrl}</TableCell>
                        <TableCell>{truncateText(agent.apiKey, 4)}</TableCell>
                        <TableCell>
                          {agent.collectionId.split(",").map((id, index) => (
                            <StyledChip
                              key={index}
                              label={truncateText(id.trim(), 4)}
                              size="small"
                              onClick={() =>
                                window.open(
                                  `${agent.endpointUrl}/explore/${id.trim()}`,
                                  "_blank",
                                )
                              }
                            />
                          ))}
                        </TableCell>
                        <TableCell>{truncateText(agent.prompt, 100)}</TableCell>
                        <TableCell>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => handleEdit(agent)}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => handleDelete(agent.id)}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle>{isEditing ? "Edit Agent" : "Add New Agent"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={formData.name}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <Tooltip title="Name info">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
          <TextField
            margin="dense"
            name="endpointUrl"
            label="Endpoint URL"
            type="text"
            fullWidth
            value={formData.endpointUrl}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <Tooltip title="Your troweb org URL e.g. https://myorg.troweb.app">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
          <TextField
            margin="dense"
            name="apiKey"
            label="API Key"
            type="text"
            fullWidth
            value={formData.apiKey}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <Tooltip title="This is required if your org is private. Generate API key from your troweb profile page">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
          <TextField
            margin="dense"
            name="collectionId"
            label="Collection ID"
            type="text"
            fullWidth
            value={formData.collectionId}
            onChange={handleCollectionIdChange}
            InputProps={{
              endAdornment: (
                <Tooltip title="The id of the collection containing your knowledge">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
          <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1 }}>
            {collectionIds.map((id, index) => (
              <StyledChip
                key={index}
                label={id}
                onDelete={() => handleChipDelete(id)}
              />
            ))}
          </Box>
          <TextField
            margin="dense"
            name="prompt"
            label="Prompt"
            type="text"
            fullWidth
            multiline
            rows={6}
            value={formData.prompt}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <Tooltip title="Prompt ">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {isEditing ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </StyledDialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Agent URL copied to clipboard"
      />
    </Box>
  );
}
