import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Chip,
  Tooltip,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import {
  getAgents,
  getAgentChats,
  deleteChat,
  getAgentById,
} from "../services/api";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[3],
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const ChatMessage = styled(Box)(({ theme, isUser }) => ({
  display: "flex",
  justifyContent: isUser ? "flex-end" : "flex-start",
  marginBottom: theme.spacing(2),
  "& > div": {
    maxWidth: "70%",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: isUser
      ? theme.palette.primary.light
      : theme.palette.grey[200],
    color: isUser
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary,
  },
}));

function ManageChats() {
  const [agents, setAgents] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedChat, setSelectedChat] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalChats, setTotalChats] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { agentId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    if (agentId) {
      setSelectedAgent(agentId);
      fetchAgentAndChats(agentId);
    }
  }, [agentId, page, rowsPerPage]);

  const fetchAgents = async () => {
    setLoading(true);
    try {
      const response = await getAgents();
      setAgents(response.data);
    } catch (error) {
      console.error("Error fetching agents:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAgentAndChats = async (id) => {
    setLoading(true);
    try {
      const [agentResponse, chatsResponse] = await Promise.all([
        getAgentById(id),
        getAgentChats(id, page * rowsPerPage, rowsPerPage),
      ]);
      setSelectedAgent(agentResponse.data);
      setChats(chatsResponse.data.chats);
      setTotalChats(chatsResponse.data.total);
    } catch (error) {
      console.error("Error fetching agent and chats:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAgentChange = (event) => {
    setSelectedAgent(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedAgent) {
      navigate(`/chats/${selectedAgent}`);
    }
  };

  const handleDeleteChat = async (chatId) => {
    if (window.confirm("Are you sure you want to delete this chat?")) {
      setLoading(true);
      try {
        await deleteChat(agentId, chatId);
        fetchAgentAndChats(agentId);
      } catch (error) {
        console.error("Error deleting chat:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOpenChat = (chat) => {
    setSelectedChat(chat);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "-" : date.toLocaleString();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!agentId) {
    return (
      <Box>
        <Typography variant="h4" gutterBottom>
          Select an Agent
        </Typography>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel id="agent-select-label">Agent</InputLabel>
          <Select
            labelId="agent-select-label"
            id="agent-select"
            value={selectedAgent}
            label="Agent"
            onChange={handleAgentChange}
          >
            {agents.map((agent) => (
              <MenuItem key={agent.id} value={agent.id}>
                {agent.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedAgent}
        >
          View Chats
        </StyledButton>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Chats for {selectedAgent?.name}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Total Chats: {totalChats}
      </Typography>
      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Creation Date</TableCell>
              <TableCell>Number of Messages</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chats.map((chat) => (
              <TableRow key={chat.id}>
                <TableCell>{chat.userId}</TableCell>
                <TableCell>{formatDate(chat.createdDate)}</TableCell>
                <TableCell>
                  <Chip
                    label={chat.history.length}
                    color="primary"
                    onClick={() => handleOpenChat(chat)}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="View Chat">
                    <IconButton
                      onClick={() => handleOpenChat(chat)}
                      color="primary"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Chat">
                    <IconButton
                      onClick={() => handleDeleteChat(chat.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <TablePagination
        component="div"
        count={totalChats}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
      <StyledButton
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/chats")}
      >
        Back to Agents
      </StyledButton>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Chat History</DialogTitle>
        <DialogContent>
          <Box sx={{ height: "60vh", overflowY: "auto" }}>
            {selectedChat?.history.map((message, index) => (
              <ChatMessage key={index} isUser={message.role == 'user'}>
                <div>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw, rehypeSanitize]}
                    remarkPlugins={[remarkGfm]}
                  > 
                    {message.content}
                  </ReactMarkdown>
                </div>
              </ChatMessage>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ManageChats;
